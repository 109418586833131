import { Core } from '@everfi/reactifi';
import i18n from 'lib/utility/i18n';
import PropTypes from 'prop-types';

const SharePlaylistMessage = (props) => {
  const { playlistName, onShare } = props;

  return (
    <Core.Layout
      variant="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      py={10}
      mx="auto"
      w="60%"
    >
      <Core.Typography>
        {i18n.t(
          "Fantastic job! 🎉 You've completed the course on {{ name }}.",
          { name: playlistName }
        )}
        <br/>
        {i18n.t(
          'Share your success with your friends and inspire them to start their own financial journey!'
        )}
      </Core.Typography>
      <Core.Button
        onClick={onShare}
        data-action="share-playlist-cta"
        borderRadius="8px"
        height="100%"
        padding="16px 24px"
      >
        <Core.Label
          label={{
            type: 'iconLeft',
            options: {
              text: i18n.t('Share with your network'),
              icon: {
                iconName: 'share-nodes',
                prefix: 'far',
              },
            },
          }}
        />
      </Core.Button>
    </Core.Layout>
  );
};

SharePlaylistMessage.propTypes = {
  onShare: PropTypes.func,
  playlistName: PropTypes.string.isRequired,
};

export default SharePlaylistMessage;
