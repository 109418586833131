import i18n from 'lib/utility/i18n';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { Button } from 'reactifi';
import { constants } from '../constants';
import PaginatedContent from './PaginatedContent';
import SharePlaylistMessage from './SharePlaylistMessage';

const Queue = (props) => {
  const {
    activePage,
    completedButtonLabel = i18n.t('View In Progress'),
    completedItems,
    content,
    handleContentNav,
    handleTabChange,
    isLibrary,
    isSearching,
    onShareLink,
    playlistName,
    processStatus,
    setActivePage,
    showIcons,
  } = props;

  const paginatedContentProps = {
    activePage,
    handleContentNav,
    setActivePage,
    showIcons,
    processStatus,
  };

  const getContent = useCallback((isCompleted) => {
    return content.filter((contentItem) => {
      const { user_content: { progress } = {} } = contentItem;
      return isCompleted
        ? progress?.status === constants.COMPLETED
        : progress?.status !== constants.COMPLETED;
    });
  });

  const onViewInProgress = useCallback(() => {
    handleTabChange(0);
  });

  const completedContent = getContent(true);
  const playlistContent = getContent(false);

  const getEmptyListMessage = () => {
    if (!isLibrary) {
      return (
        <SharePlaylistMessage
          onShare={onShareLink}
          playlistName={playlistName}
        />
      );
    }

    if (isSearching) {
      return (
        <div className="empty-list">
          {i18n.t(
            'We did not find related activities. You may want to update your search.'
          )}
        </div>
      );
    }

    return (
      <div className="empty-list">
        {i18n.t(
          'You have completed all the activities. Check out the Completed tab to review.'
        )}
      </div>
    );
  };

  if (!completedItems) {
    return playlistContent.length > 0 ? (
      <PaginatedContent
        {...paginatedContentProps}
        collection={playlistContent}
      />
    ) : (
      getEmptyListMessage()
    );
  } else {
    return completedContent.length > 0 ? (
      <PaginatedContent
        {...paginatedContentProps}
        collection={completedContent}
      />
    ) : (
      <div className="empty-list">
        {i18n.t('Your completed activities appear here!')}
        <Button
          branded={true}
          className="btn-view-inprog capitalize"
          label={completedButtonLabel}
          onClick={onViewInProgress}
          style="primary"
        />
      </div>
    );
  }
};

Queue.propTypes = {
  activePage: PropTypes.number,
  completedButtonLabel: PropTypes.string,
  completedItems: PropTypes.bool,
  content: PropTypes.array.isRequired,
  handleContentNav: PropTypes.func,
  handleTabChange: PropTypes.func,
  isLibrary: PropTypes.bool,
  isSearching: PropTypes.bool,
  onShareLink: PropTypes.func,
  playlistName: PropTypes.string,
  processStatus: PropTypes.string,
  setActivePage: PropTypes.func,
  showIcons: PropTypes.bool,
};

export default Queue;
