import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Cookies from 'js.cookie';
import $ from 'jquery';
import { BrowserUpdate } from 'reactifi/dist/es/browserUpdate';
import { ProgressSpinner } from 'reactifi/dist/es/components/progress-spinner';
import { injectCustomerColorsCSS } from 'lib/utility/helpers';
import * as learnerDashboardActions from '../actions/learnerDashboardActions';
import mapStateToProps from '../store/learnerDashboardMapper';
import OtherPlaylists from '../components/OtherPlaylists';
import Playlist from '../components/Playlist';
import GatewayModal from '../components/GatewayModal';
import Diagnostic from '../../../components/diagnostic';
import GenericModal from 'lib/components/GenericModal';
import DashboardCustomization from '../components/DashboardCustomization';
import { getGatewayPromptCounts } from '../../../common/functions';
import { FROM_LIBRARY_QUERY_STRING } from '../../../common/constants';
import isEqual from 'lodash/isEqual';
import { sanitizeUrl } from '@braintree/sanitize-url';

class LearnerDashboardContainer extends React.Component {
  static propTypes = {
    currentUriContext: PropTypes.string.isRequired,
    dashboardCustomization: PropTypes.shape({
      enabled: PropTypes.bool,
      text: PropTypes.string,
    }),
    dashboardCustomizationId: PropTypes.string,
    dispatch: PropTypes.func.isRequired,
    diagnostic: PropTypes.object,
    diagnosticId: PropTypes.number,
    gateways: PropTypes.array,
    incentiveId: PropTypes.string,
    locales: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        url: PropTypes.string,
        current: PropTypes.bool,
      })
    ),
    personalizedPlaylistId: PropTypes.number,
    playlist: PropTypes.object,
    playlistId: PropTypes.number,
    program: PropTypes.shape({
      auth_capabilities: PropTypes.object,
      diagnostics: PropTypes.array,
      id: PropTypes.string,
      playlists: PropTypes.array,
      primary_color: PropTypes.string,
      remove_content_images: PropTypes.bool,
      secondary_color: PropTypes.string,
      slug: PropTypes.string,
      custom_login_text: PropTypes.string,
    }),
    programId: PropTypes.number.isRequired,
    ui: PropTypes.shape({
      answers: PropTypes.array,
      authUrls: PropTypes.array,
      closedContent: PropTypes.string,
      foundryLinkUrl: PropTypes.string,
      loginText: PropTypes.string,
    }).isRequired,
    userId: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
  };

  constructor(props) {
    super(props);

    this.state = {};
    this.actions = bindActionCreators(
      { ...learnerDashboardActions },
      this.props.dispatch
    );
  }

  async componentDidMount() {
    BrowserUpdate();

    const {
      dashboardCustomizationId,
      diagnosticId,
      personalizedPlaylistId,
      playlistId,
      programId,
      userId,
    } = this.props;

    await this.actions.selectProgram(programId);

    this.checkAndRedirectToLibrary(this.props.program?.slug);

    if (dashboardCustomizationId) {
      this.actions.loadCustomization(dashboardCustomizationId);
    }

    if (playlistId) {
      await this.actions.loadPlaylistItems(playlistId);
      this.actions.loadUserContent(programId, userId);
    } else if (personalizedPlaylistId) {
      await this.actions.loadPersonalizedPlaylistItems(personalizedPlaylistId);
      this.actions.loadUserContent(programId, userId);
    } else if (diagnosticId) {
      await this.actions.selectDiagnostic(diagnosticId);
      this.setDiagnosticStatus('pending');
    }

    await this.actions.loadIncentives(programId, playlistId);

    this.actions.loadOtherPlaylists(programId);
    this.actions.loadOtherDiagnostics(programId);

    await this.actions.loadGateways(programId, getGatewayPromptCounts());
    this.handleDashboardLoadGateways();

    if (this.props.ui.closedContent) {
      Cookies.remove('closed_content_id');
      this.handleAfterContentGateways();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.program && !isEqual(this.props.program, prevProps.program)) {
      injectCustomerColorsCSS(
        this.props.program.primary_color,
        this.props.program.secondary_color
      );
    }
    if (
      !!this.props.incentiveId &&
      (!prevProps.incentiveId ||
        prevProps.incentiveId !== this.props.incentiveId)
    ) {
      this.actions.loadAllIncentiveActivities(
        this.props.programId,
        this.props.userId
      );
    }
  }

  checkAndRedirectToLibrary = (programSlug) => {
    if (!programSlug) return;

    const urlParams = new URLSearchParams(window.location.search);
    const shouldRedirectToLibrary = Boolean(
      urlParams.get(FROM_LIBRARY_QUERY_STRING)
    );

    if (shouldRedirectToLibrary) {
      urlParams.delete(FROM_LIBRARY_QUERY_STRING);
      window.location = sanitizeUrl(
        `/student/dashboard/${programSlug}/content_library?${urlParams.toString()}`
      );
    }
  };

  goToStudentUrl = (content) => {
    window.location = sanitizeUrl(
      `${this.props.currentUriContext}/${content.id}${window.location.search}`
    );
  };

  handleAfterContentGateways = () =>
    this.setGateway('playlist:afterContentReturn');
  handleBeforeContentGateways = (onGatewayFinished) =>
    this.setGateway('playlist:beforeContentStart', onGatewayFinished);
  handleDashboardLoadGateways = () => this.setGateway('dashboard:load');
  handleIncentiveCompleteGateways = (onGatewayFinished) =>
    this.setGateway('incentive:complete', onGatewayFinished);

  setGateway = (gatewayAt, onGatewayFinished = () => {}) => {
    const { gateways } = this.props;
    if (gateways.length > 0) {
      const gateway = gateways.find((gateway) => gateway.at === gatewayAt);
      if (gateway) {
        this.setState({ gateway, onGatewayFinished });
      } else {
        onGatewayFinished();
      }
    } else {
      onGatewayFinished();
    }
  };

  closeGateway = () => {
    const { gateway, onGatewayFinished } = this.state;
    if (gateway?.next) {
      const nextGateway = this.props.gateways.find(
        (gw) => gw.type === gateway.next
      );
      if (nextGateway) {
        this.setState({ gateway: nextGateway });
      } else {
        this.setState(
          { gateway: null, onGatewayFinished: null },
          onGatewayFinished
        );
      }
    } else {
      this.setState(
        { gateway: null, onGatewayFinished: null },
        onGatewayFinished
      );
    }
  };

  handleContentNav = (content) => {
    this.handleBeforeContentGateways(() => this.goToStudentUrl(content));
  };

  setDiagnosticStatus = (diagnosticStatus) => {
    if (diagnosticStatus === 'pending') {
      $('.footer').addClass('survey-pending'); // open survey footer visibility so people can change languages
    }
    this.setState({ diagnosticStatus });
  };

  render() {
    const { dashboardCustomization, diagnostic, program, playlist, ui } =
      this.props;
    const { diagnosticStatus } = this.state;

    if (!program) return <ProgressSpinner />;

    if (diagnostic && diagnosticStatus === 'pending') {
      return (
        <GenericModal
          canClose={false}
          fullscreen={true}
          locales={this.props.locales}
          logo={null}
          modalClass="diagnostic"
          show={true}
          showMenu={true}
        >
          <Diagnostic
            diagnostic={diagnostic}
            containerSelector="diagnostic"
            setDiagnosticStatus={this.setDiagnosticStatus}
          />
        </GenericModal>
      );
    } else if (playlist || diagnosticStatus === 'failed') {
      return (
        <div className="learner-dash-container primary secondary">
          <Playlist
            key="playlists"
            diagnostic={diagnostic}
            handleContentNav={this.handleContentNav}
            handleIncentiveCompleteGateways={
              this.handleIncentiveCompleteGateways
            }
            playlist={playlist}
            program={program}
            setDiagnosticStatus={this.setDiagnosticStatus}
          />
          <div className="row">
            <div className="border-bottom" />
          </div>
          <div className="row other-playlists-row">
            <OtherPlaylists
              program={program}
              currentPlaylistId={playlist ? playlist.id : 0}
            />
          </div>
          {playlist && this.state.gateway && (
            <GatewayModal
              answers={ui.answers}
              authUrls={ui.authUrls}
              foundryLinkUrl={ui.foundryLinkUrl}
              gatewayToPresent={this.state.gateway}
              loginText={this.props.program.custom_login_text}
              onModalClose={this.closeGateway}
              programAuthCaps={program.auth_capabilities}
              programId={program.id}
            />
          )}
          <DashboardCustomization
            customization={dashboardCustomization}
            program={program}
          />
        </div>
      );
    } else {
      return <ProgressSpinner />;
    }
  }
}

export default connect(mapStateToProps)(LearnerDashboardContainer);
