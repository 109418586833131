import { CopyUtil, Core, GenericModal } from '@everfi/reactifi';
import { sanitize } from 'dompurify';
import { getCustomizedText } from 'lib/utility/helpers';
import i18n from 'lib/utility/i18n';
import { kebabCase } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { getLocaleVersion } from '../../../common/functions';
import Header from './Header';
import Queue from './Queue';
export default class Playlist extends React.Component {
  static propTypes = {
    diagnostic: PropTypes.object,
    handleContentNav: PropTypes.func.isRequired,
    handleIncentiveCompleteGateways: PropTypes.func.isRequired,
    program: PropTypes.shape({
      remove_content_images: PropTypes.bool,
      text_customization: PropTypes.shape({
        activities_text: PropTypes.string,
      }),
    }).isRequired,
    playlist: PropTypes.shape({
      content: PropTypes.array,
      id: PropTypes.string,
      incentive: PropTypes.object,
      startedCount: PropTypes.number,
      completedCount: PropTypes.number,
    }),
    setDiagnosticStatus: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      completedItems: false,
      activeKey: 0,
      isShareModalOpen: false,
    };
  }

  retakeSurvey = () => {
    this.props.setDiagnosticStatus('pending');
  };

  handleTabChange = (key) => {
    if (Number(key) === 1) {
      this.setState({ activePage: 1, completedItems: true, activeKey: 1 });
    } else {
      this.setState({ activePage: 1, completedItems: false, activeKey: 0 });
    }
  };

  setActivePage = (activePage) => {
    this.setState({ activePage });
  };

  get playlistName() {
    return getLocaleVersion(this.props.playlist, 'name');
  }

  get playlistLink() {
    return sanitize(window.location.href);
  }

  toggleShareModal = () => {
    this.setState((prev) => ({ isShareModalOpen: !prev.isShareModalOpen }));
  };

  copyPlaylistLink = () => {
    CopyUtil.copyToClipboard(this.playlistLink);
  };

  getTabbedContent = (processStatus) => {
    let { playlist, program, handleContentNav } = this.props;

    return (
      <Queue
        activePage={this.state.activePage}
        completedItems={this.state.completedItems}
        content={playlist.content}
        handleContentNav={handleContentNav}
        handleTabChange={this.handleTabChange}
        onShareLink={this.toggleShareModal}
        playlistName={this.playlistName}
        processStatus={processStatus}
        setActivePage={this.setActivePage}
        showIcons={!program.remove_content_images}
      />
    );
  };

  render() {
    let { diagnostic, handleIncentiveCompleteGateways, playlist, program } =
      this.props;

    if (diagnostic && (!playlist || (playlist && !playlist.content.length))) {
      const activities_text = getCustomizedText(
        program.text_customization,
        'activities_text'
      );
      let activitiesNotFoundMessage = i18n.t('activitiesNotFound', {
        activities: activities_text,
      });
      return (
        <div className="activities-not-found">
          <h3>{activitiesNotFoundMessage}</h3>
          <button onClick={this.retakeSurvey} className="btn btn-link">
            {i18n.t('Take Survey Again')}
          </button>
        </div>
      );
    } else if (playlist) {
      let progressStatuses = [
        {
          key: 'inprogress',
          title: i18n.t('In Progress'),
          count: playlist.startedCount,
        },
        {
          key: 'completed',
          title: i18n.t('Completed'),
          count: playlist.completedCount,
        },
      ];

      return (
        <React.Fragment>
          <Header
            diagnostic={diagnostic}
            handleIncentiveCompleteGateways={handleIncentiveCompleteGateways}
            incentive={diagnostic ? diagnostic.incentive : playlist.incentive}
            program={program}
            playlist={playlist}
            retakeSurvey={this.retakeSurvey}
            onShare={this.toggleShareModal}
          />
          <div className="row playlist-row">
            <div className="container">
              <div className="row playlists clearfix">
                <div className="col-12">
                  <Tabs
                    activeKey={this.state.activeKey}
                    className="row uppercase branded nav-tabs learner-tabs ui-default"
                    data-attribute="playlist-tabs"
                    data-object-type="playlists"
                    data-object-id={playlist.id}
                    id="playlist-tabs"
                    onSelect={(k) => this.handleTabChange(k)}
                  >
                    {progressStatuses.map((statusObj, index) => {
                      const kebabTabTitle = `${kebabCase(statusObj.title)}-tab`;

                      return (
                        <Tab
                          key={index}
                          data-action={`open-${kebabTabTitle}`}
                          data-attribute={kebabTabTitle}
                          data-object-type="playlists"
                          data-object-id={playlist.id}
                          eventKey={index}
                          title={`${statusObj.title} (${statusObj.count})`}
                        >
                          {this.getTabbedContent(statusObj.title)}
                        </Tab>
                      );
                    })}
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
          <GenericModal
            canClose={true}
            onClose={this.toggleShareModal}
            show={this.state.isShareModalOpen}
            title={i18n.t('Share Pathway')}
          >
            <Core.Typography mb={'sm'}>
              {i18n.t(
                'Share the gift of knowledge! 🌟 Share the {{ name }} course with your friends and help them start their financial journey too!',
                { name: this.playlistName }
              )}
            </Core.Typography>
            <Core.Layout
              id="share-playlist-modal"
              variant="flex"
              justifyContent="space-between"
              alignItems="center"
              gap="sm"
            >
              <Core.Input
                id="copy-playlist-link-input"
                isReadOnly={true}
                title="Playlist link"
                type={Core.FieldTypes.text}
                value={this.playlistLink}
                h="2.75rem"
              />
              <Core.Button onClick={this.copyPlaylistLink}>
                <Core.Label
                  label={{
                    type: 'iconLeft',
                    options: {
                      text: i18n.t('Copy'),
                      icon: {
                        iconName: 'copy'
                      },
                    },
                  }}
                />
              </Core.Button>
            </Core.Layout>
          </GenericModal>
        </React.Fragment>
      );
    } else {
      return null;
    }
  }
}
